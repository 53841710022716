export const aboutMeText =
  "Мне нравится создавать удобные, быстрые и адаптивные веб-приложения.\n" +
  "Фронтенд для меня — это идеальное сочетание технологий, логики и визуала. А в работе я ценю чистый код, внимание к деталям и продуманный UI/UX.\n" +
  'Я начинал свой путь с курсов в "Яндекс Практикуме", где работал по Agile-методологии, осваивал командную разработку и проходил строгие код-ревью. Сейчас активно развиваюсь, работаю над проектами на фрилансе и нацелен на создание удобных, эффективных и современных решений.\n' +
  "\n" +
  "Что я умею:\n" +
  "✅ Разработка SPA-приложений на React\n" +
  "✅ Верстка по макету (Figma, PSD) — Pixel Perfect\n" +
  "✅ Чистая верстка или с использованием UI Kit\n" +
  "✅ Интерактивные формы с валидацией (React Hook Form)\n" +
  "✅ Реализация ленивой загрузки\n" +
  "✅ Работа с REST API, интеграция с backend\n" +
  "✅ Авторизация пользователей (Firebase, JWT, OAuth)\n" +
  "✅ Оптимизация SEO для CSR веб-приложений\n" +
  "✅ Деплой и настройка сервера (Nginx)\n" +
  "✅ Базовый backend (Node.js, Express, MongoDB)\n" +
  "\n" +
  "Использую технологии:\n" +
  "🔹 HTML, CSS, JavaScript (ES6+) \n" +
  "🔹 React, React Router, MobX, React Hook Form \n" +
  "🔹 Tailwind, Bootstrap, Stylus  \n" +
  "🔹 REST API, Axios, Fetch\n" +
  "🔹 Git, GitHub\n" +
  "🔹 Firebase Auth, Firestore\n" +
  "🔹 Vite, Webpack\n" +
  "\n" +
  "Я всегда открыт для сотрудничества и готов помочь с вашим проектом.";
