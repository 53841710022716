import bootstrapImg from "../images/skills/Bootstrap.png";
import html5Img from "../images/skills/html5.png";
import css3Img from "../images/skills/css3.png";
import reactImg from "../images/skills/react.svg";
import UXimg from "../images/skills/UX.png";
import gitImg from "../images/skills/git.png";
import nodeImg from "../images/skills/image.png";
import sassImg from "../images/skills/sass.png";
import jsImg from "../images/skills/js.png";
import tailwind from "../images/skills/tailwind.svg";

export const skills = {
  html: {
    name: "HTML5",
    image: html5Img,
  },
  css: {
    name: "CSS3",
    image: css3Img,
  },
  js: {
    name: "JavaScript",
    image: jsImg,
  },
  react: {
    name: "React.JS",
    image: reactImg,
  },
  sass: {
    name: "Sass",
    image: sassImg,
  },
  bootstrap: {
    name: "Bootstrap",
    image: bootstrapImg,
  },
  tailwind: {
    name: "Tailwind CSS",
    image: tailwind,
  },
  node: {
    name: "Node.JS",
    image: nodeImg,
  },
  git: {
    name: "Git",
    image: gitImg,
  },
  uiux: {
    name: "UI/UX",
    image: UXimg,
  },
};
